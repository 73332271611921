import { stripRichTextWrapperTag, richText } from '../../utils/html';
import { adapter as imageAdapter } from '../../components/cloud-image/utils';
import { getEkomiData, defaultAccount, getRating } from '~/utils/ekomi';

const ekomiAccountMarkets = {
  'international-care-fr': 'international',
  moto: 'moto',
  marine: 'marine',
};

const getFirstRatings = (shouldUseAPI, prismic, ekomi, ekomiAccount, i18n) => {
  if (shouldUseAPI && ekomi) {
    const items = ekomi.slice(0, 3);
    return items.map((item) => {
      const questions = item.forms[0].questions;
      const ratingQuestion = questions.find(
        (question) => question.question_type === 'rating'
      );
      const feedbackQuestion = questions.find(
        (question) => question.question_type === 'feedback'
      );

      return {
        tag: ekomiAccountMarkets[ekomiAccount] || null,
        market: ekomiAccountMarkets[ekomiAccount] || null,
        rating: getRating(
          ratingQuestion.answer_value.options[0].option_id,
          i18n
        ),
        text: feedbackQuestion.answer_value,
        date: new Intl.DateTimeFormat(i18n.locale).format(
          new Date(item.published_at * 1000)
        ),
      };
    });
  } else {
    const items = prismic.slice(0, 3);
    return items.map(({ rating, market, text, date }) => {
      const marketId = market
        ? market
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        : 'default';

      return {
        rating: getRating(rating, i18n),
        tag: market || null,
        text,
        date: new Intl.DateTimeFormat(i18n.locale).format(new Date(date)),
        marketId,
      };
    });
  }
};

export default async (
  { primary },
  pageData,
  { $store, $i18n, $enhancedLinkSerializer, $config }
) => {
  if (!primary.ratings?.data) {
    return null;
  }

  const { data } = primary.ratings;
  const {
    title,
    text,
    average_rating,
    badge,
    badge_alt,
    view_all_link,
    ratings,
    ekomi_api,
  } = data;

  let ekomiAccount, reviews, answers;
  const shouldUseAPI = !!ekomi_api;

  if (shouldUseAPI) {
    ekomiAccount =
      pageData?.ekomi_account ||
      pageData?.market?.data?.ekomi_account ||
      defaultAccount;
    reviews = $store.state.ekomi.reviews[ekomiAccount];
    answers = $store.state.ekomi.answers[ekomiAccount];

    if (!reviews?.average_rating) {
      reviews = await getEkomiData(ekomiAccount, 'reviews', {
        $store,
        $config,
      });
    }

    if (!answers?.average_rating) {
      answers = await getEkomiData(ekomiAccount, 'answers', {
        $store,
        $config,
      });
    }
  }

  const averageRating = getRating(
    shouldUseAPI ? reviews?.average_rating : average_rating,
    $i18n
  );
  const firstRatings = getFirstRatings(
    shouldUseAPI,
    ratings,
    answers,
    ekomiAccount,
    $i18n
  );

  const link = $enhancedLinkSerializer(view_all_link);

  return {
    title: title && stripRichTextWrapperTag(title),
    text: richText(text),
    showText: !!text,
    badge: badge && imageAdapter(badge, badge_alt),
    cards: firstRatings,
    ratings: shouldUseAPI
      ? {
          rating: parseFloat(averageRating.replace(',', '.')),
          showText: true,
          ratingLabel: $i18n.t('ratingsInfo.lite.label', {
            count: reviews?.review_count || 0,
            href: link?.href,
          }),
          variant: 'large',
        }
      : null,
  };
};
